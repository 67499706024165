<template>
  <b-img
      :src="appLogoImage"
      alt="logo"
  />
</template>
<script>
import { BImg } from 'bootstrap-vue';
import { $themeConfig } from '@themeConfig';

export default {
  components: {
    BImg,
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
};
</script>
